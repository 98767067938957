<template>
  <div>
    <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose">
      <el-button type="primary" plain @click="handleReset">恢复默认</el-button>
      <div>
        <div class="title">快捷操作</div>
        <div v-for="item in listConf" :key="item.field_alias" class="block">
          <div class="block-title">{{item.field_text}}</div>
          <el-radio-group v-model="item.t_is_use">
            <el-radio :label="1">显示</el-radio>
            <el-radio :label="0">隐藏</el-radio>
          </el-radio-group>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="handleTrue" :loading="settingLoading">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { tableConfSet, restoreReq } from '@/api/statistics/goods.js';

export default {
  data() {
    return {
      settingLoading: false,
    };
  },
  props: {
    title: String,
    dialogVisible: Boolean,
    listConf: Array,
  },
  methods: {
    handleReset() {
      restoreReq('standing_book')
        .then((res) => {
          if (res.code === 1) {
            this.handleClose();
            this.$emit('onColumns');
          }
        });
    },
    handleClose() {
      this.$emit('onClose');
    },
    handleTrue() {
      this.settingLoading = true;
      tableConfSet({
        function_code: 'standing_book',
        data: this.listConf,
      })
        .then((res) => {
          if (res.code === 1) {
            this.settingLoading = false;
            this.$message.success('设置成功');
            this.$emit('onSuccess');
            this.handleClose();
          }
        })
        .catch(() => {
          this.settingLoading = false;
        });
    },
  },
};
</script>

<style lang="less" scoped>
.block {
  display: flex;
  width: 100%;
  margin-bottom: 10px;
  &-title {
    width: 100px;
  }
}
.title {
  line-height: 40px;
  font-weight: bold;
}
/deep/ .el-form-item {
  margin-bottom: 0px;
}
</style>
